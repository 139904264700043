@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Nav links with rounded edges and default transparent border */
.navbar-nav .nav-link {
	border-radius: 8px;
	border-bottom: 2px solid transparent;
	transition: border-color 0.3s, background-color 0.3s;
	/* Smooth transition effect */
}

/* Hover effect for Nav links with rounded edges */
.navbar-nav .nav-link:hover {
	background-color: rgba(211, 211, 211, 0.329);
	border-bottom: 2px solid rgba(255, 166, 0, 0.692);
}

.messages-indicator {
	position: relative;
	display: inline-block;
}

.new-messages-badge {
	position: absolute;
	top: -4px;
	/* Adjust as needed */
	right: -5px;
	/* Adjust as needed */
	width: 12px;
	/* Size of the badge */
	height: 12px;
	/* Size of the badge */
	border-radius: 50%;
	/* Makes it a circle */
	background-color: orange;
	/* The badge color */
	border: 2px solid white;
	/* Optional: makes it stand out on any background */
}

.author-card {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 10px;
	background-color: white;
}

.countdown-container {
	color: white;
}

.timer-display {
	text-align: center;
	padding: 20px;
	border-radius: 20px;
	backdrop-filter: blur(10px);
}

.gsi-material-button {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-webkit-appearance: none !important;
	background-color: #131314;
	background-image: none;
	border: 1px solid #747775;
	-webkit-border-radius: 4px;
	border-radius: 4px !important;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #e3e3e3;
	cursor: pointer !important;
	font-family: "Roboto", arial, sans-serif !important;
	font-size: 14px !important;
	height: 40px;
	letter-spacing: 0.25px;
	outline: none;
	overflow: hidden;
	padding: 0 12px;
	position: relative;
	text-align: center;
	-webkit-transition: background-color .218s, border-color .218s, box-shadow
		.218s;
	transition: background-color .218s, border-color .218s, box-shadow .218s;
	vertical-align: middle;
	white-space: nowrap;
	width: auto;
	max-width: 400px;
	min-width: min-content;
	border-color: #8e918f;
}

.gsi-material-button .gsi-material-button-icon {
	height: 20px;
	margin-right: 12px;
	min-width: 20px;
	width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
	-webkit-align-items: center;
	align-items: center;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	height: 100%;
	justify-content: space-between;
	position: relative;
	width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
	-webkit-flex-grow: 1;
	flex-grow: 1;
	font-family: "Roboto", arial, sans-serif;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
	-webkit-transition: opacity .218s;
	transition: opacity .218s;
	bottom: 0;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.gsi-material-button:disabled {
	cursor: default;
	background-color: #13131461;
	border-color: #8e918f1f;
}

.gsi-material-button:disabled .gsi-material-button-state {
	background-color: #e3e3e31f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
	opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
	opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
	background-color: white;
	opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
	-webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px
		rgba(60, 64, 67, 0.15);
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px
		rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
	background-color: white;
	opacity: 8%;
}
