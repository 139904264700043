/* Define default (light theme) variables */
:root {
	--background-color: #fff8e7;
	--background-color-footer: #fae3ad;
	/* Light theme background */
	--background-color-dark: #333;
	--background-color-dark-footer: #5c636a;
	/* Dark theme background, choose an appropriate dark color */
	--text-color: #000;
	--text-color-muted: #666;
	/* Default text color for light background */
	--text-color-dark: #fff;
	--text-color-dark-muted: #bbb;
	/* Text color for dark background */
	--svg-filter-white: invert(99%) sepia(4%) saturate(562%) hue-rotate(246deg)
		brightness(118%) contrast(100%);
}

/* Dark mode adjustments */
[data-theme="dark"] {
	--background-color: var(--background-color-dark);
}

.themed-text {
	color: var(--text-color);
}

[data-theme="dark"] .themed-text {
	color: var(--text-color-dark);
}

.themed-text-muted {
	color: var(--text-color-muted);
}

[data-theme="dark"] .themed-text-muted {
	color: var(--text-color-dark-muted);
}

[data-theme="dark"] .svg-filter-white {
	filter: var(--svg-filter-white);
}

.footer {
	background-color: var(--background-color-footer);
}

[data-theme="dark"] .footer {
	background-color: var(--background-color-dark-footer);
}

.themed-component {
	background-color: var(--text-color-dark);
}

[data-theme="dark"] .themed-component {
	background-color: var(--background-color-dark);
}

body {
	margin: 0;
	background-color: var(--background-color);
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
