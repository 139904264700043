@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&display=swap");

.feature-card {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.feature-card .card-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.feature-card svg {
	margin-bottom: 15px;
	color: #6a2c70;
	/* Example color */
}

.search-radio-box {
	border: 1px solid #d0d0d7;
	border-radius: .3em;
	margin: .3em;
}

.landing-div {
	color: #6a2c70;
}

.profile-tab-card-heading {
	font-size: 1.25rem;
}

.Navbar {
	background-color: #4a5b6a;
}

.custom-image {
	width: 100%;
	height: 10rem;
	object-fit: fill;
}

.form-group-margin {
	margin-bottom: 1rem;
}

.app-logo {
	font-family: "Josefin Slab", sans-serif;
}

.app-logo-sub {
	font-size: 3vw;
	/* Scale down for mobile devices */
}

.app-logo-span {
	font-size: 5.5vw;
	/* Scale down for mobile devices */
}

@media (max-width: 768px) {
	.app-logo-sub {
		font-size: 7vw;
		/* Scale down for mobile devices */
	}
}

@media (max-width: 768px) {
	.app-logo-span {
		font-size: 17vw;
		/* Scale down for mobile devices */
	}
}
