// Light theme is the default
@import 'light-theme.scss'; // This contains your original variables and imports

// When dark mode is active
.dark-mode {
  @import 'dark-theme.scss'; // Import or define dark theme variables here
  
  // Override Bootstrap variables or apply dark theme styles
  // Ensure that the map-merge for $theme-colors is included here if it changes
  $theme-colors: map-merge($theme-colors, $dark-theme-colors);
  
  // Re-import Bootstrap to apply the dark theme variables
  // This may not be the most efficient way as it will include Bootstrap twice
  // Consider customizing this approach to only override necessary styles
  @import '~bootstrap/scss/bootstrap.scss';
  
  // Your additional custom styles for dark mode go here
}